import React, { Component } from "react";
import NavBar from './NavBar';

const header = (
    <div>
      <img
        className="header"
        src={process.env.PUBLIC_URL + "../images/black-logo.JPG"}
        alt="header" />
    </div>
);

class Header extends Component {
  render() {
    return (
      <div className="center">
        <div className="blackBox">
          {header}
          <NavBar />
        </div>
      </div>
    )
  }
};

export default Header;
