import React, { Component } from "react";

class Contact extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="aboutMobile">

		        <h1>Contact</h1>

							<div className="iconMobile">
							<a href="mailto:djvaginawoolf@gmail.com" target="_blank" rel="noopener noreferrer">
								<img
									className="iconImgMobile"
									src={process.env.PUBLIC_URL + "../icons/gmail2.png"}
									alt="email" />
							</a>
							</div>

							<br />

							<div className="iconMobile">
							<a href="https://www.facebook.com/vgina.woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="iconImgMobile"
									src={process.env.PUBLIC_URL + "../icons/facebook.png"}
									alt="facebook" />
							</a>
							</div>

							<br />

							<div className="iconMobile">
							<a href="https://www.instagram.com/vagina__woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="iconImgMobile"
									src={process.env.PUBLIC_URL + "../icons/insta.png"}
									alt="instagram" />
							</a>
							</div>

							<br />

							<div className="iconMobile">
							<a href="https://soundcloud.com/vagina-woolf" target="_blank" rel="noopener noreferrer">
								<img
									className="iconImgMobile"
									src={process.env.PUBLIC_URL + "../icons/Soundcloud-icon.png"}
									alt="soundcloud" />
							</a>
							</div>

							<br />

							<div className="iconMobile">
							<a href="https://www.mixcloud.com/vagina_woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="iconImgMobile"
									src={process.env.PUBLIC_URL + "../icons/mixcloud.png"}
									alt="mixcloud" />
							</a>
							</div>

						</div>

    );
  } else {
		return(
			<div className="about">

			<br /><br />

				<div className="row">

					<div className="width75">
		        <h1>Contact</h1>

						<div className="row">

							<div className="icon">
							<a href="mailto:djvaginawoolf@gmail.com" target="_blank" rel="noopener noreferrer">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../icons/gmail2.png"}
									alt="email" />
							</a>
							</div>

							<div className="icon">
							<a href="https://www.facebook.com/vgina.woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../icons/facebook.png"}
									alt="facebook" />
							</a>
							</div>

							<div className="icon">
							<a href="https://www.instagram.com/vagina__woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../icons/insta.png"}
									alt="instagram" />
							</a>
							</div>

							<div className="icon">
							<a href="https://soundcloud.com/vagina-woolf" target="_blank" rel="noopener noreferrer">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../icons/Soundcloud-icon.png"}
									alt="soundcloud" />
							</a>
							</div>

							<div className="icon">
							<a href="https://www.mixcloud.com/vagina_woolf/" target="_blank" rel="noopener noreferrer">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../icons/mixcloud.png"}
									alt="mixcloud" />
							</a>
							</div>

						</div>


		      </div>

					<div className="imgCont25">
							<img
								className="aboutImg"
								src={process.env.PUBLIC_URL + "../images/headphones.jpg"}
								alt="logo" />
					</div>
				</div>

				<br /> <br />

		</div>
    );
  }
}
}

export default Contact;
