import React, { Component } from "react";

class Home extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="imgContainerMobile">
				<img
					className="homeImgMobile"
					src={process.env.PUBLIC_URL + "../images/photo1.JPG"}
					alt="vw @ sweatshop, #1" />
			</div>
    );
  } else {
		return(

			<div className="homeBody">

				<div className="row">

					<div className="imgContainer">
						<img
							className="homeImg"
							src={process.env.PUBLIC_URL + "../images/photo1.JPG"}
							alt="vw @ sweatshop, #1" />
					</div>

					<div className="imgContainer">
						<img
							className="homeImg"
							src={process.env.PUBLIC_URL + "../images/photo2.JPG"}
							alt="vw @ sweatshop, #2" />
					</div>

					<div className="imgContainer">
						<img
							className="homeImg"
							src={process.env.PUBLIC_URL + "../images/photo3.JPG"}
							alt="vw @ sweatshop, #3" />
					</div>

				</div>
				</div>
    );
  }
}
}

export default Home;
