import React, { Component } from "react";

class About extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="aboutMobile">

						<h1>About</h1>

						<div className="imgContMobile">
								<img
									className="aboutImgMobile"
									src={process.env.PUBLIC_URL + "../images/vwoolf_main.jpg"}
									alt="logo" />
						</div>

						<h3>after a few years' hiatus to focus on grad school,
						dj vagina woolf is ready to return to the house music scene,
						this time in philadelphia after her relocation from providence, ri,
						where she got her start. like her literary namesake, virginia
						woolf, she emulates the stream-of-consciousness style, seamlessly
						blending together tracks from sometimes disparate but complementary
						sub-genres of dance as though stitching together sentences. she
						weaves together a real-time narrative using atmospheric tech and
						deep house to set the stage for the hop &amp; skip of uk garage,
						the sultry romance of nu-disco, &amp; the triumphs of chicago house.</h3>

						<br />

						<h1>Listen</h1>

						<div className="mixcloudMobile">
							<iframe
								width="100%"
								height="60"
								src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Frailroad-crossing-1118%2F"
								frameborder="0"
								title="rxr" >
							</iframe>
						</div>

						<br />

						<div className="mixcloudMobile">
							<iframe
								width="100%"
								height="60"
								src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Fdadt-ft-vagina-woolf-pt-1%2F"
								frameborder="0"
								title="dadt" >
							</iframe>
						</div>

						<br />

						<div className="mixcloudMobile">
							<iframe
								width="100%"
								height="60"
								src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Fpride-2018-mix%2F"
								frameborder="0"
								title="pride">
							</iframe>
						</div>
					</div>
    );
  } else {
			return(
				<div className="about">

					<div className="row">

						<div className="width75">
							<h1>About</h1>

							<h3>after a few years' hiatus to focus on grad school,
							dj vagina woolf is ready to return to the house music scene,
							this time in philadelphia after her relocation from providence, ri,
							where she got her start. like her literary namesake, virginia
							woolf, she emulates the stream-of-consciousness style, seamlessly
							blending together tracks from sometimes disparate but complementary
							sub-genres of dance as though stitching together sentences. she
							weaves together a real-time narrative using atmospheric tech and
							deep house to set the stage for the hop &amp; skip of uk garage,
							the sultry romance of nu-disco, &amp; the triumphs of chicago house.</h3>

						</div>

						<div className="imgCont25">
								<img
									className="aboutImg"
									src={process.env.PUBLIC_URL + "../images/vwoolf_main.jpg"}
									alt="logo" />
						</div>
					</div>


						<div className="width75">
							<h1>Listen</h1>
						</div>


						<div className="row">

							<div className="mixcloud">
								<iframe
									width="100%"
									height="60"
									src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Frailroad-crossing-1118%2F"
									frameborder="0"
									title="rxr" >
								</iframe>
							</div>

							<div className="mixcloud">
								<iframe
									width="100%"
									height="60"
									src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Fdadt-ft-vagina-woolf-pt-1%2F"
									frameborder="0"
									title="dadt" >
								</iframe>
							</div>

							<div className="mixcloud">
								<iframe
									width="100%"
									height="60"
									src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fvagina_woolf%2Fpride-2018-mix%2F"
									frameborder="0"
									title="pride">
								</iframe>
							</div>
						</div>

					</div>
    );
  }
}
}

export default About;
