import React, { Component } from "react";

class Footer extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="footerMobile">
        <h3>dj vagina woolf &reg; 2015 - 2021</h3>
      </div>
    );
  } else {
    return (
			<footer>
        <h3>dj vagina woolf &reg; 2015 - 2021</h3>
      </footer>
    );
  }
}
}

export default Footer;
