import React, { Component } from "react";

class Events extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="aboutMobile">

		        <h1>Upcoming Events</h1>

            <h3>for booking inquiries, please email djvaginawoolf@gmail.com</h3>

						<br />

		        <h1>Past Events</h1>

						<div className="event">
						<h3>
							guest mix for doubleshot sunday
							<br />
							6.6.21
						</h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/doubleshot.jpg"}
									alt="doubleshot sunday poster" />
						</div>

						<br />

  						<div className="event">
              <h3>
                pride 2018 @ black sheep
                <br />
                6.16.18
              </h3>
  								<img
  									className="eventImg"
  									src={process.env.PUBLIC_URL + "../images/6.16.18_pride1.jpg"}
  									alt="vw @ pride" />
  						</div>

            <br />

						<div className="event">
            <h3>
              pride 2018 @ black sheep
              <br />
              6.16.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/6.16.18_pride.jpg"}
									alt="pride poster" />
						</div>

            <br />

    						<div className="event">
                <h3>
                  alley party @ pvdfest 2018
                  <br />
                  6.9.18
                </h3>
    								<img
    									className="eventImg"
    									src={process.env.PUBLIC_URL + "../images/6.9.18_pvdfest1.jpg"}
    									alt="alley party" />
    						</div>

          <br />

						<div className="event">
            <h3>
              alley party @ pvdfest 2018
              <br />
              6.9.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/6.9.18_alley.jpg"}
									alt="alley poster" />
						</div>


          <br />

						<div className="eventWide">
            <h3>
              nightcap @ victor spoils
              <br />
              3.10.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/3.10.18_nightcap.jpg"}
									alt="nightcap poster" />
						</div>

            <br />

              <div className="event">
              <h3>
                don't ask don't tell @ great scott
                <br />
                2.24.18
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../images/2.24.18_dadt.jpg"}
                    alt="dadt" />
              </div>

          <br />

  					<div className="eventWide">
            <h3>
              don't ask don't tell @ great scott
              <br />
              2.24.18
            </h3>
  							<img
  								className="eventImg"
  								src={process.env.PUBLIC_URL + "../posters/2.24.18_dadt.jpg"}
  								alt="dadt poster" />
  					</div>


            <br />

    					<div className="event">
              <h3>
                sweatshop @ salon
                <br />
                2.10.18
              </h3>
    							<img
    								className="eventImg"
    								src={process.env.PUBLIC_URL + "../images/photo1.JPG"}
    								alt="sweatshop" />
    					</div>


            <br />

      				<div className="event">
              <h3>
                happy birthday, vagina @ providence g
                <br />
                1.21.18
              </h3>
      						<img
      							className="eventImg"
      							src={process.env.PUBLIC_URL + "../images/1.21.18_rooftop.jpg"}
      							alt="happy bday" />
      				</div>

            <br />

      				<div className="event">
              <h3>
                happy birthday, vagina @ providence g
                <br />
                1.21.18
              </h3>
      						<img
      							className="eventImg"
      							src={process.env.PUBLIC_URL + "../posters/happybday.jpg"}
      							alt="happy bday poster" />
      				</div>


          <br />

    				<div className="eventWide">
            <h3>
              bubbles &amp; beats @ providence g
              <br />
              winter & spring 2018
            </h3>
    						<img
    							className="eventImg"
    							src={process.env.PUBLIC_URL + "../posters/bubbles_and_beats.jpg"}
    							alt="bubbles and beats poster" />
    				</div>

						<br />

              <div className="eventWide">
              <h3>
                rise @ providence g
                <br />
                11.19.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/11.19.17_rise.jpg"}
                    alt="rise poster" />
              </div>

              <br />

                <div className="event">
                <h3>
                  pizza pop-up @ brooklyn pizza
                  <br />
                  11.18.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/11.18.17_pizza.jpg"}
                      alt="pizza pop-up poster" />
                </div>


            <br />

              <div className="eventWide">
              <h3>
                nightcap @ victor spoils
                <br />
                10.14.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/10.14.17_nightcap.jpg"}
                    alt="nightcap poster" />
              </div>

              <br />

                <div className="event">
                <h3>
                  bubbles &amp; beats @ providence g
                  <br />
                  10.1.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../images/10.1.17_rooftop.png"}
                      alt="bubbles and beats poster" />
                </div>

            <br />


              <div className="event">
              <h3>
                vagina woolf solo set @ salon
                <br />
                8.24.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/salon_solo.jpg"}
                    alt="salon poster" />
              </div>

              <br />

                <div className="eventWide">
                <h3>
                  nightcap @ victor spoils
                  <br />
                  8.4.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/8.4.17_nightcap.jpg"}
                      alt="nightcap poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    bubbles &amp; beats @ providence g
                    <br />
                    summer &amp; fall 2017
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/bubbles_summer2017.jpg"}
                        alt="bubbles and beats poster" />
                  </div>


              <br />

                <div className="event">
                <h3>
                  superdope thursdays @ salon
                  <br />
                  6.29.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/6.2017_superdope.jpg"}
                      alt="superdope poster" />
                </div>

                  <br />

                    <div className="event">
                    <h3>
                      alley party @ pvdfest 2017
                      <br />
                      6.3.17
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/6.3.17_pvdfest.jpg"}
                          alt="alley party" />
                    </div>

              <br />

                <div className="eventWide">
                <h3>
                  alley party @ pvdfest 2017
                  <br />
                  6.3.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/6.17_alley.jpg"}
                      alt="alley poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    house party after bubbles &amp; beats
                    <br />
                    5.28.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../images/5.28.17_houseparty.jpg"}
                        alt="house party" />
                  </div>

                <br />

                <div className="eventWide">
                <h3>
                  bubbles &amp; beats @ providence g
                  <br />
                  spring 2017
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/bubbles_and_beats2.jpg"}
                      alt="bubbles and beats poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    2.11.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../images/2.11.17_sweatshop2.png"}
                        alt="sweatshop" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      sweatshop @ salon
                      <br />
                      2.11.17
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/2.11.17_sweatshop.jpg"}
                          alt="sweatshop" />
                    </div>


                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    2.11.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/2.11.17_sweatshop.jpg"}
                        alt="sweatshop poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      vaudeville specakular @ aurora
                      <br />
                      12.30.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/12.30.16_aurora.jpg"}
                          alt="vaudeville" />
                    </div>

                <br />

                  <div className="event">
                  <h3>
                    vaudeville specakular @ aurora
                    <br />
                    12.30.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/12.30.16_vaudeville.jpg"}
                        alt="vaudeville poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      heavy rotation @ salon
                      <br />
                      11.26.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/heavy_rotation_pic.jpg"}
                          alt="heavy rotation" />
                    </div>

                    <br />

                      <div className="event">
                      <h3>
                        heavy rotation @ salon
                        <br />
                        11.26.16
                      </h3>
                          <img
                            className="eventImg"
                            src={process.env.PUBLIC_URL + "../posters/heavy_rotation.jpg"}
                            alt="heavy rotation poster" />
                      </div>

                  <br />

                    <div className="event">
                    <h3>
                      railroad crossing (rxr)
                      <br />
                      11.18.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/11.18.16_rxr.jpg"}
                          alt="rxr" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      substructure @ the midway cafe
                      <br />
                      9.30.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/9.30.16_midway.jpg"}
                          alt="substructure" />
                    </div>


                <br />

                  <div className="event">
                  <h3>
                    substructure @ the midway cafe
                    <br />
                    9.30.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/9.30.16_substructure.jpg"}
                        alt="substructure poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      local 121
                      <br />
                      fall 2016
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/local121.jpg"}
                          alt="local 121" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      sweatshop @ salon
                      <br />
                      6.11.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/6.11.16_sweatshop_decks.jpg"}
                          alt="sweatshop" />
                    </div>

                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    6.11.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/6.11.16_sweatshop.jpg"}
                        alt="sweatshop poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      rooftop grooves @ the providence g
                      <br />
                      5.20.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/5.20.16_rooftop.jpg"}
                          alt="rooftop" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      rooftop grooves @ the providence g
                      <br />
                      5.6.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/5.6.16_rooftop.jpg"}
                          alt="rooftop" />
                    </div>


                <br />

                  <div className="eventWide">
                  <h3>
                    spring 2016 @ the rooftop at providence g
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/4.29.16_rooftop.jpg"}
                        alt="rooftop grooves poster" />
                  </div>

                <br />

                  <div className="event">
                  <h3>
                    comfort club thursday @ salon
                    <br />
                    3.31.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/3.16.16_bday.jpg"}
                        alt="comfort club" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      comfort club thursday @ salon
                      <br />
                      1.14.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/1.14.16_comfortclub.jpg"}
                          alt="comfort club" />
                    </div>

                  <br />


          </div>
    );
  } else {
		return(
			<div className="about">

					<div>
		        <h1>Upcoming Events</h1>

            <h3>for booking inquiries, please email djvaginawoolf@gmail.com</h3>
          </div>

          <br /><br />

          <div>
		        <h1>Past Events</h1>
          </div>

					<div className="event">
					<h3>
						guest mix for doubleshot sunday
						<br />
						6.6.21
					</h3>
							<img
								className="eventImg"
								src={process.env.PUBLIC_URL + "../posters/doubleshot.jpg"}
								alt="doubleshot sunday poster" />
					</div>

					<br />

            <div className="event">
            <h3>
              pride 2018 @ black sheep
              <br />
              6.16.18
            </h3>
                <img
                  className="eventImg"
                  src={process.env.PUBLIC_URL + "../images/6.16.18_pride2.jpg"}
                  alt="pride crowd" />
            </div>

            <br />

  						<div className="event">
              <h3>
                pride 2018 @ black sheep
                <br />
                6.16.18
              </h3>
  								<img
  									className="eventImg"
  									src={process.env.PUBLIC_URL + "../images/6.16.18_pride1.jpg"}
  									alt="vw @ pride" />
  						</div>

            <br />

						<div className="event">
            <h3>
              pride 2018 @ black sheep
              <br />
              6.16.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/6.16.18_pride.jpg"}
									alt="pride poster" />
						</div>

            <br />

  						<div className="event">
              <h3>
                alley party @ pvdfest 2018
                <br />
                6.9.18
              </h3>
  								<img
  									className="eventImg"
  									src={process.env.PUBLIC_URL + "../images/6.9.18_pvdfest2.jpg"}
  									alt="alley party crowd" />
  						</div>

              <br />

    						<div className="event">
                <h3>
                  alley party @ pvdfest 2018
                  <br />
                  6.9.18
                </h3>
    								<img
    									className="eventImg"
    									src={process.env.PUBLIC_URL + "../images/6.9.18_pvdfest1.jpg"}
    									alt="alley party" />
    						</div>

          <br />

						<div className="event">
            <h3>
              alley party @ pvdfest 2018
              <br />
              6.9.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/6.9.18_alley.jpg"}
									alt="alley poster" />
						</div>


          <br />

						<div className="eventWide">
            <h3>
              nightcap @ victor spoils
              <br />
              3.10.18
            </h3>
								<img
									className="eventImg"
									src={process.env.PUBLIC_URL + "../posters/3.10.18_nightcap.jpg"}
									alt="nightcap poster" />
						</div>

            <br />

              <div className="event">
              <h3>
                don't ask don't tell @ great scott
                <br />
                2.24.18
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../images/2.24.18_dadt.jpg"}
                    alt="dadt" />
              </div>

          <br />

  					<div className="eventWide">
            <h3>
              don't ask don't tell @ great scott
              <br />
              2.24.18
            </h3>
  							<img
  								className="eventImg"
  								src={process.env.PUBLIC_URL + "../posters/2.24.18_dadt.jpg"}
  								alt="dadt poster" />
  					</div>


            <br />

    					<div className="event">
              <h3>
                sweatshop @ salon
                <br />
                2.10.18
              </h3>
    							<img
    								className="eventImg"
    								src={process.env.PUBLIC_URL + "../images/photo1.JPG"}
    								alt="sweatshop" />
    					</div>


            <br />

      				<div className="event">
              <h3>
                happy birthday, vagina @ providence g
                <br />
                1.21.18
              </h3>
      						<img
      							className="eventImg"
      							src={process.env.PUBLIC_URL + "../images/1.21.18_rooftop.jpg"}
      							alt="happy bday" />
      				</div>

            <br />

      				<div className="event">
              <h3>
                happy birthday, vagina @ providence g
                <br />
                1.21.18
              </h3>
      						<img
      							className="eventImg"
      							src={process.env.PUBLIC_URL + "../posters/happybday.jpg"}
      							alt="happy bday poster" />
      				</div>


          <br />

    				<div className="eventWide">
            <h3>
              bubbles &amp; beats @ providence g
              <br />
              winter & spring 2018
            </h3>
    						<img
    							className="eventImg"
    							src={process.env.PUBLIC_URL + "../posters/bubbles_and_beats.jpg"}
    							alt="bubbles and beats poster" />
    				</div>

            <br />

              <div className="eventWide">
              <h3>
                rise @ providence g
                <br />
                11.19.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/11.19.17_rise.jpg"}
                    alt="rise poster" />
              </div>

              <br />

                <div className="event">
                <h3>
                  pizza pop-up @ brooklyn pizza
                  <br />
                  11.18.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/11.18.17_pizza.jpg"}
                      alt="pizza pop-up poster" />
                </div>


            <br />

              <div className="eventWide">
              <h3>
                nightcap @ victor spoils
                <br />
                10.14.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/10.14.17_nightcap.jpg"}
                    alt="nightcap poster" />
              </div>

              <br />

                <div className="event">
                <h3>
                  bubbles &amp; beats @ providence g
                  <br />
                  10.1.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../images/10.1.17_rooftop.png"}
                      alt="bubbles and beats poster" />
                </div>

            <br />


              <div className="event">
              <h3>
                vagina woolf solo set @ salon
                <br />
                8.24.17
              </h3>
                  <img
                    className="eventImg"
                    src={process.env.PUBLIC_URL + "../posters/salon_solo.jpg"}
                    alt="salon poster" />
              </div>

              <br />

                <div className="eventWide">
                <h3>
                  nightcap @ victor spoils
                  <br />
                  8.4.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/8.4.17_nightcap.jpg"}
                      alt="nightcap poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    bubbles &amp; beats @ providence g
                    <br />
                    summer &amp; fall 2017
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/bubbles_summer2017.jpg"}
                        alt="bubbles and beats poster" />
                  </div>


              <br />

                <div className="event">
                <h3>
                  superdope thursdays @ salon
                  <br />
                  6.29.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/6.2017_superdope.jpg"}
                      alt="superdope poster" />
                </div>

                  <br />

                    <div className="event">
                    <h3>
                      alley party @ pvdfest 2017
                      <br />
                      6.3.17
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/6.3.17_pvdfest.jpg"}
                          alt="alley party" />
                    </div>

              <br />

                <div className="eventWide">
                <h3>
                  alley party @ pvdfest 2017
                  <br />
                  6.3.17
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/6.17_alley.jpg"}
                      alt="alley poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    house party after bubbles &amp; beats
                    <br />
                    5.28.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../images/5.28.17_houseparty.jpg"}
                        alt="house party" />
                  </div>

                <br />

                <div className="eventWide">
                <h3>
                  bubbles &amp; beats @ providence g
                  <br />
                  spring 2017
                </h3>
                    <img
                      className="eventImg"
                      src={process.env.PUBLIC_URL + "../posters/bubbles_and_beats2.jpg"}
                      alt="bubbles and beats poster" />
                </div>

                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    2.11.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../images/2.11.17_sweatshop2.png"}
                        alt="sweatshop" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      sweatshop @ salon
                      <br />
                      2.11.17
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/2.11.17_sweatshop.jpg"}
                          alt="sweatshop" />
                    </div>


                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    2.11.17
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/2.11.17_sweatshop.jpg"}
                        alt="sweatshop poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      vaudeville specakular @ aurora
                      <br />
                      12.30.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/12.30.16_aurora.jpg"}
                          alt="vaudeville" />
                    </div>

                <br />

                  <div className="event">
                  <h3>
                    vaudeville specakular @ aurora
                    <br />
                    12.30.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/12.30.16_vaudeville.jpg"}
                        alt="vaudeville poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      heavy rotation @ salon
                      <br />
                      11.26.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/heavy_rotation_pic.jpg"}
                          alt="heavy rotation" />
                    </div>

                    <br />

                      <div className="event">
                      <h3>
                        heavy rotation @ salon
                        <br />
                        11.26.16
                      </h3>
                          <img
                            className="eventImg"
                            src={process.env.PUBLIC_URL + "../posters/heavy_rotation.jpg"}
                            alt="heavy rotation poster" />
                      </div>

                  <br />

                    <div className="event">
                    <h3>
                      railroad crossing (rxr)
                      <br />
                      11.18.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/11.18.16_rxr.jpg"}
                          alt="rxr" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      substructure @ the midway cafe
                      <br />
                      9.30.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/9.30.16_midway.jpg"}
                          alt="substructure" />
                    </div>


                <br />

                  <div className="event">
                  <h3>
                    substructure @ the midway cafe
                    <br />
                    9.30.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/9.30.16_substructure.jpg"}
                        alt="substructure poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      local 121
                      <br />
                      fall 2016
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/local121.jpg"}
                          alt="local 121" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      sweatshop @ salon
                      <br />
                      6.11.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/6.11.16_sweatshop_decks.jpg"}
                          alt="sweatshop" />
                    </div>

                <br />

                  <div className="event">
                  <h3>
                    sweatshop @ salon
                    <br />
                    6.11.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/6.11.16_sweatshop.jpg"}
                        alt="sweatshop poster" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      rooftop grooves @ the providence g
                      <br />
                      5.20.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/5.20.16_rooftop.jpg"}
                          alt="rooftop" />
                    </div>

                  <br />

                    <div className="event">
                    <h3>
                      rooftop grooves @ the providence g
                      <br />
                      5.6.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/5.6.16_rooftop.jpg"}
                          alt="rooftop" />
                    </div>


                <br />

                  <div className="eventWide">
                  <h3>
                    spring 2016 @ the rooftop at providence g
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/4.29.16_rooftop.jpg"}
                        alt="rooftop grooves poster" />
                  </div>

                <br />

                  <div className="event">
                  <h3>
                    comfort club thursday @ salon
                    <br />
                    3.31.16
                  </h3>
                      <img
                        className="eventImg"
                        src={process.env.PUBLIC_URL + "../posters/3.16.16_bday.jpg"}
                        alt="comfort club" />
                  </div>

                  <br />

                    <div className="event">
                    <h3>
                      comfort club thursday @ salon
                      <br />
                      1.14.16
                    </h3>
                        <img
                          className="eventImg"
                          src={process.env.PUBLIC_URL + "../images/1.14.16_comfortclub.jpg"}
                          alt="comfort club" />
                    </div>

                  <br />

          </div>
    );
  }
}
}

export default Events;
