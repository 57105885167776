import React, { Component } from "react";
import { Link } from 'react-router-dom';

class NavBar extends Component {
	constructor() {
  super();
  this.state = {
    width: window.innerWidth,
  };
}

componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
}

// make sure to remove the listener
// when the component is not mounted anymore
componentWillUnmount() {
  window.removeEventListener('resize', this.handleWindowSizeChange);
}

handleWindowSizeChange = () => {
  this.setState({ width: window.innerWidth });
};

render() {
  const { width } = this.state;
  const isMobile = width <= 500;
  // the rest is the same...

  if (isMobile) {
    return (
			<div className="navBoxMobile">

        <div className="navTextMobile">
            <h1>
							<Link to="/">home</Link>
               -
							<Link to="/about">about</Link>
               -

							<Link to="/events">events</Link>
	             - 

							<Link to="/contact">contact</Link>
            </h1>
        </div>
      </div>
    );
  } else {
		return(
      <div className="navBox">

        <div className="navText">
            <h1>
							<Link to="/">home</Link>
              &nbsp;&nbsp;&nbsp;

								&nbsp;&nbsp;&nbsp;

							<Link to="/about">about</Link>
              &nbsp;&nbsp;&nbsp;

								&nbsp;&nbsp;&nbsp;

							<Link to="/events">events</Link>
	             &nbsp;&nbsp;&nbsp;

								&nbsp;&nbsp;&nbsp;

							<Link to="/contact">contact</Link>
            </h1>
        </div>
      </div>
    );
  }
}
}

export default NavBar;
